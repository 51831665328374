<template>
  <div class="ledger-index">
    <fm-title title-text="台账">
      <div class="title-sort">
        <div class="fm-tabs fm-tabs-norm">
          <div class="fm-tabs-nav">
            <div
              @click="showType = item.key"
              class="fm-tabs-nav-item"
              :class="{'fm-tabs-nav-active': item.key === showType}"
              v-for="item in titleMenus" :key="item.id">
              {{item.label}}
            </div>
          </div>
        </div>
      </div>
    </fm-title>
    <div class="ledger-content">
      <process :assets-id="assetsId" v-if="showType === 'process'"></process>
      <repair :assets-id="assetsId" v-if="showType === 'repair'"></repair>
    </div>
  </div>
</template>

<script>
import process from './process'
import repair from './repair'

export default {
  components: {
    process,
    repair
  },
  props: {
    assetsId: { type: Number, default: null }
  },
  data () {
    return {
      showType: 'process',
      titleMenus: [{
        key: 'process',
        label: '生命周期'
      },
      {
        key: 'in_out',
        label: '出入库记录'
      },
      {
        key: 'repair',
        label: '维修记录'
      }]
    }
  }
}
</script>

<style scoped lang="less">
.ledger-index {
  width: 100%;
  height: 100%;
  .ledger-content {
    width: 100%;
    height: calc(100% - 48px);
  }
  .title-sort {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
</style>