<template>
  <process-item-tmp
    :cmp="cmp"
    :allShowDetail="allShowDetail"
    :detail-list="data.purchaseBatchDetailList || []"
    :show-type="showType">
    <base-info id="purchase_method" @click="showDetail= !showDetail" :data="showData" :config="config"></base-info>
    <purchase-method-detail-info :show-type="showType" v-if="showDetail && showData && showData.id" :id="showData.id"></purchase-method-detail-info>
  </process-item-tmp>
</template>

<script>
import processItemTmp from './processItemTmp'
import purchaseDetail from './purchaseDetail'
import purchaseMethodDetailInfo from './purchaseMethodDetailInfo'
import baseInfo from './baseInfo'

export default {
  components: {
    processItemTmp,
    purchaseMethodDetailInfo,
    baseInfo
  },
  props: {
    showType: {
      type: String,
      default: 'row'
    },
    allShowDetail: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function () {return null}
    }
  },
  watch: {
    allShowDetail: {
      deep: true,
      handler () {
        this.showDetail = this.allShowDetail
      },
      immediate: true
    }
  },
  computed: {
    showData () {
      let data = Object.assign({}, this.data)
      data.passTime = (data.statusActionRecord.find(v => v.newStatusKey === 'end' && !v.isBack) || {createTime: ''}).createTime
      return data
    }
  },
  data () {
    return {
      cmp: purchaseDetail,
      showDetail: false,
      config: [{
        key: 'code',
        label: '采购方式编号'
      },
      {
        key: 'busOrgName',
        label: '职能科室'
      },
      {
        key: 'method',
        label: '采购方式'
      },
      {
        key: 'passTime',
        label: '通过日期',
        format: (data) => {
          return data.passTime ? data.passTime.slice(0, 10) : ''
        }
      }]
    }
  }
}
</script>