import { render, staticRenderFns } from "./processItemTmp.vue?vue&type=template&id=7855dea8&scoped=true&"
import script from "./processItemTmp.vue?vue&type=script&lang=js&"
export * from "./processItemTmp.vue?vue&type=script&lang=js&"
import style0 from "./processItemTmp.vue?vue&type=style&index=0&id=7855dea8&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7855dea8",
  null
  
)

export default component.exports