<template>
  <div class="detail-bdi" v-if="purchaseMethodBatchDetail" :class="{'detail-bdi-row': showType === 'row', 'detail-bdi-column': showType === 'column'}">
    <div class="detail-list">
      <div class="t-info-text" v-if="purchaseContractList.length > 0">该笔采购方式关联合同</div>
      <div style="display: flex;">
        <div class="budget-info-2" v-for="(v, index) in purchaseContractList" :key="index">
          <div class="info-text-2">合同编号：{{v.code}}</div>
          <div class="info-text-2">合同名称：{{v.name}}</div>
          <div class="info-text-2">采购负责人：{{v.principalWorkerName}}</div>
          <div class="info-text-2">签订单位：{{v.signedOrg}}</div>
          <div class="info-text-2">签订日期：{{v.signedTime ? v.signedTime.slice(0, 10) : ''}}</div>
          <div class="info-text-2">合同金额：{{v.total}}</div>
          <div class="info-text-2">采购数量：{{v.num}}</div>
        </div>
      </div>
    </div>
    <div>
      <div class="t-info-text">审批记录</div>
      <record :record-list="purchaseMethodBatchDetail && purchaseMethodBatchDetail.statusActionRecord ? purchaseMethodBatchDetail.statusActionRecord : []"></record>
    </div>
  </div>
  <div v-else style="margin: 20px;">无预算</div>
</template>

<script>
import record from './record'

import {
  purchaseMethodBatchDetailsRequest,
  purchaseContractRequest
} from '@/api'

export default {
  components: {
    record
  },
  props: {
    id: { type: Number, defualt: null },
    showType: {
      type: String,
      default: 'row'
    }
  },
  watch: {
    id: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      purchaseMethodBatchDetail: [],
      purchaseContractList: []
    }
  },
  methods: {
    async loadData () {
      this.purchaseMethodBatchDetail = null
      this.purchaseContractList = []
      if (!this.id) {
        return
      }
      let datas = await purchaseMethodBatchDetailsRequest.get({
        id: this.id,
        statusActionRecord: 1
      })
      this.purchaseMethodBatchDetail = datas.length > 0 ? datas[0] : null
      if (this.purchaseMethodBatchDetail && this.purchaseMethodBatchDetail.purchaseContractIds) {
        this.purchaseContractList = await purchaseContractRequest.get({
          ids: this.purchaseMethodBatchDetail.purchaseContractIds
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.detail-bdi-row {
  flex-direction: column;
}
.detail-bdi-column {
  flex-direction: row;
}
.detail-bdi {
  display: flex;
  margin: 10px 0;
  .t-info-text {
    font-weight: 600;
    padding: 0 10px;
  }
  .budget-info-2 {
    padding: 5px 10px 0;
    margin-bottom: 10px;
    .info-text-2 {
      font-size: 12px;
      white-space:nowrap;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      margin-right: 10px
    }
    i {
      color: #FB6D8F;
      padding-left: 10px;
    }
  }
  .p-chart {
    height: 180px;
    display: flex;
    .chart-item {
      width: 150px;
      height: 100%;
    }
  }
}
</style>