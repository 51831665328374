<template>
  <div class="recode-list">
    <table style="width: 100%;" v-if="showRecordList && showRecordList.length > 0">
      <tr v-for="(item, index) in showRecordList" :key="index">
        <td>{{item.time ? item.time.slice(0, 10) : ''}}</td>
        <td>
          <span @mouseover="openUpdate2(index, true)" @mouseout="openUpdate2(index, false)">{{item.statusLabel ? item.statusLabel.replace('中', '') : ''}}</span>
          <fm-poper :value="openMap2[index]" position="bottom">
            <div class="long-remark">
              {{item.statusLabel ? item.statusLabel.replace('中', '') : ''}}
            </div>
          </fm-poper>
        </td>
        <td>{{item.name}}</td>
        <td>{{item.actionLabel}}</td>
        <td>
          <span @mouseover="openUpdate(index, true)" @mouseout="openUpdate(index, false)">{{item.remark ? item.remarkTitle + '：' : ''}}{{item.remark}}</span>
          <fm-poper :value="openMap[index]" position="bottom">
            <div class="long-remark">
              {{item.remarkTitle + '：' + item.remark}}
            </div>
          </fm-poper>
        </td>
      </tr>
    </table>
    <div class="no-data" v-else>暂无处理记录</div>
  </div>
</template>

<script>
export default {
  props: {
    recordList: { type: Array, defualt: () => [] }
  },
  data () {
    return {
      openMap: {},
      openMap2: {}
    }
  },
  methods: {
    openUpdate (index, value) {
      this.$set(this.openMap, index, value)
    },
    openUpdate2 (index, value) {
      this.$set(this.openMap2, index, value)
    }
  },
  computed: {
    showRecordList () {
      let data = []
      let dataBase = JSON.parse(JSON.stringify(this.recordList))
      dataBase.forEach(v => {
        data.push({
          time: v.createTime,
          name: v.workerName,
          actionLabel: v.statusActionLabel,
          statusKey: v.statusKey,
          statusLabel: v.statusLabel,
          remark: v.remark,
          remarkTitle: v.statusActionKey === 'draw_switch_wait_1' ? '提交说明' : '审批意见'
        })
        if (v.isBack) {
          data.push({
            time: v.backTime,
            statusKey: v.statusKey,
            statusLabel: v.statusLabel,
            name: v.backWorkerName,
            actionLabel: '撤销' + v.statusActionLabel,
            remark: v.backRemark,
            remarkTitle: '撤销说明'
          })
        }
      })
      data.sort((a, b) => {
        return new Date(a.time).getTime() - new Date(b.time).getTime()
      })
      return data
    }
  }
}
</script>

<style lang="less" scoped>
.recode-list {
  max-height: 150px;
  overflow-y: auto;
  margin: 0 10px;
  font-size: 12px;
  color: #515a6e;
  td {
    height: 22px;
    white-space:nowrap;
    padding-right: 10px;
    max-width: 100px;
    overflow: hidden;
    text-overflow:ellipsis;
  }
  .no-data {
    padding-bottom: 10px;
  }
  .long-remark {
    max-width: 150px;
    white-space: normal;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
    padding: 10px;
  }
}
</style>